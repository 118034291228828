import { FC } from "react";
import RegisterButton from "../../shared/register";
import LayoutHeaderBrandLogo from "./logo";
import LayoutHeaderNav from "./nav";

const LayoutHeader: FC = () => {
  return (
    <header className="section py-3 flex justify-between items-center fixed top-0 left-0 right-0 bg-white z-50">
      <LayoutHeaderBrandLogo />
      <LayoutHeaderNav />
      <RegisterButton />
    </header>
  );
};
``;

export default LayoutHeader;
