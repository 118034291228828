import { FC } from "react";

const LayoutFooterSubscribe: FC = () => {
  return (
    <div>
      <h6 className="text-cornflower-blue font-semibold">SUBSCRIBE</h6>
      <p className="text-white text-sm font-light mt-3">
        We understand your business and your passion to drive it forward.
      </p>
    </div>
  );
};

export default LayoutFooterSubscribe;
