import type { GatsbyBrowser } from "gatsby";
import React from "react";
import Layout from "./src/components/layout";
import "./src/styles/global.css";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <Layout>{element}</Layout>;
};
