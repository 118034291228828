import { FC } from "react";

const FacebookSVG: FC = () => {
  return (
    <svg
      className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] fill-viking stroke-viking"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="2em"
      width="2em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M426.8 64H85.2C73.5 64 64 73.5 64 85.2v341.6c0 11.7 9.5 21.2 21.2 21.2H256V296h-45.9v-56H256v-41.4c0-49.6 34.4-76.6 78.7-76.6 21.2 0 44 1.6 49.3 2.3v51.8h-35.3c-24.1 0-28.7 11.4-28.7 28.2V240h57.4l-7.5 56H320v152h106.8c11.7 0 21.2-9.5 21.2-21.2V85.2c0-11.7-9.5-21.2-21.2-21.2z"></path>
    </svg>
  );
};

export default FacebookSVG;
