import { FC } from "react";
import FacebookSVG from "../../shared/svgs/facebook";
import WhiteMailSVG from "../../shared/svgs/white-mail";
import LayoutFooterContact from "./contact";
import LayoutFooterQuickLinks from "./quick-links";
import LayoutFooterSubscribe from "./subscribe";

const LayoutFooter: FC = () => {
  return (
    <div className="bg-gradient-to-b from-[#81C9DF] to-[#21B0A8] py-10 section ">
      <div className="max-w-5xl mx-auto">
        <div className="grid md:grid-cols-2 gap-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div className="pt-6">
              <img src="/images/logo_white.png" width={136} height={92} />
              {/* <p className="text-sm font-light text-white mt-4">
                WhatzOn is a ipsum dolor sit amet, consectetur adipisicing
                commodi, voluptate quaerat iure elit.
              </p> */}
            </div>
            <LayoutFooterQuickLinks />
          </div>
          <div>
            <div className="grid md:grid-cols-2 gap-6">
              <LayoutFooterContact />
              <LayoutFooterSubscribe />
            </div>
            {/* <LayoutFooterSubscribeInput /> */}
          </div>
        </div>

        <div className="flex md:flex-row flex-col-reverse items-center justify-between mt-10">
          <p className="text-sm font-light text-white mb:mt-0 mt-5">
            Made By{" "}
            <a
              href="https://www.zartek.in"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Zartek
            </a>
          </p>
          <p className="text-sm font-light text-white mb:mt-0 mt-5">
            Copyright © WhatzOn {new Date().getFullYear()}. All right reserved.
          </p>

          <div className="flex space-x-3 items-center">
            <a
              href="mailto:support@shopwithwhatzon.com"
              className="bg-white p-2 rounded-full"
            >
              <WhiteMailSVG />
            </a>
            {/* <button className="bg-white p-2 rounded-full">
              <LinkedInSVG />
            </button> */}
            <button className="bg-white p-2 rounded-full">
              <FacebookSVG />
            </button>
            {/* <button className="bg-white p-2 rounded-full">
              <InstagramSVG />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutFooter;
