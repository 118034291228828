import { Link } from "gatsby";
import { FC } from "react";

const LayoutHeaderBrandLogo: FC = () => {
  return (
    <Link to="/">
      <img alt="logo" src="/images/icon.png" width={80} />
    </Link>
  );
};

export default LayoutHeaderBrandLogo;
