import { FC } from "react";

const LayoutFooterContact: FC = () => {
  return (
    <div>
      <h6 className="text-cornflower-blue font-semibold">CONTACT</h6>
      <p className="text-white text-sm font-light mt-3">
        University Road, CUSAT PO, Kochi, Kerala, India - 682022
        support@shopwithwhatzon.com
      </p>
    </div>
  );
};

export default LayoutFooterContact;
