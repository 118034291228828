import { Link } from "gatsby";
import { FC } from "react";

const RegisterButton: FC = () => {
  return (
    <Link
      to="/register"
      className="primary-btn lg:text-sm text-xs 
      lg:px-5 px-3 py-2 rounded-xl smooth-animate 
      hover:scale-105 hover:-translate-y-0.5 hover:shadow-md
      sm:mr-0 mr-6"
    >
      REGISTER
    </Link>
  );
};

export default RegisterButton;
