import { FC } from "react";

const LayoutFooterQuickLinks: FC = () => {
  return (
    <div>
      <h6 className="text-cornflower-blue font-semibold">QUICK LINKS</h6>
      <ul className="text-white text-sm font-light mt-3">
        <li className="mb-1">
          <a href="/">Intro</a>
        </li>
        <li className="mb-1">
          <a href="/#features">Features</a>
        </li>
        <li className="mb-1">
          <a href="/#about">About</a>
        </li>
        <li className="mb-1">
          <a href="/#download">Download</a>
        </li>
        <li className="mb-1">
          <a href="/#contact-us">Contact Us</a>
        </li>
        <li className="mb-1">
          <a href="/register">Register</a>
        </li>
      </ul>
    </div>
  );
};

export default LayoutFooterQuickLinks;
