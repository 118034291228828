import { useLocation } from "@reach/router";
import { Link, navigate } from "gatsby";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import useHandleClose from "../../../hooks/useHandleClose";
import MenuSVG, { MenuCloseSVG } from "../../shared/svgs/menu";

const LayoutHeaderNav: FC = () => {
  const { hash, pathname } = useLocation();
  const [show, setShow] = useState(false);

  const menuRef = useRef<HTMLElement>(null);

  useHandleClose(() => {
    setShow(false);
  }, menuRef);

  useEffect(() => {
    if (pathname === "/") {
      navigate(`/${hash}`);
    }
  }, []);

  return (
    <Fragment>
      <nav
        ref={menuRef}
        className={`${
          show
            ? "fixed animate-fade-in top-20 left-0 right-0 bg-white shadow-xl rounded-b-xl py-2"
            : "sm:block hidden"
        }`}
      >
        <ul
          className={`${
            show ? "sm:flex-row flex-col" : ""
          } flex sm:space-x-4 text-cornflower-blue lg:text-sm text-xs`}
        >
          <li
            className={`${
              hash === "" && pathname === "/"
                ? "border-cornflower-blue -translate-y-0.5"
                : "border-transparent"
            } border-b font-medium smooth-animate hover:border-cornflower-blue
          hover:-translate-y-0.5 sm:w-auto w-full sm:py-0 py-2 sm:px-0 px-4`}
            onClick={() => setShow(false)}
          >
            <Link to="/">INTRO</Link>
          </li>
          <li
            className={`${
              hash === "#features"
                ? "border-cornflower-blue -translate-y-0.5"
                : "border-transparent"
            } border-b font-medium smooth-animate hover:border-cornflower-blue
          hover:-translate-y-0.5 sm:w-auto w-full sm:py-0 py-2 sm:px-0 px-4`}
            onClick={() => setShow(false)}
          >
            <Link to="/#features">FEATURES</Link>
          </li>
          <li
            className={`${
              hash === "#about"
                ? "border-cornflower-blue -translate-y-0.5"
                : "border-transparent"
            } border-b font-medium smooth-animate hover:border-cornflower-blue
          hover:-translate-y-0.5 sm:w-auto w-full sm:py-0 py-2 sm:px-0 px-4`}
            onClick={() => setShow(false)}
          >
            <Link to="/#about">ABOUT</Link>
          </li>
          <li
            className={`${
              hash === "#download"
                ? "border-cornflower-blue -translate-y-0.5"
                : "border-transparent"
            } border-b font-medium smooth-animate hover:border-cornflower-blue
          hover:-translate-y-0.5 sm:w-auto w-full sm:py-0 py-2 sm:px-0 px-4`}
            onClick={() => setShow(false)}
          >
            <Link to="/#download">DOWNLOAD</Link>
          </li>
          <li
            className={`${
              hash === "#contact-us"
                ? "border-cornflower-blue -translate-y-0.5"
                : "border-transparent"
            } border-b font-medium smooth-animate hover:border-cornflower-blue
          hover:-translate-y-0.5 sm:w-auto w-full sm:py-0 py-2 sm:px-0 px-4`}
            onClick={() => setShow(false)}
          >
            <Link to="/#contact-us">CONTACT US</Link>
          </li>

          {/* <li
            className={`${"border-transparent"} border-b font-medium smooth-animate hover:border-cornflower-blue
          hover:-translate-y-0.5 sm:w-auto w-full sm:py-0 py-2 sm:px-0 px-4`}
            onClick={() => setShow(false)}
          >
            <a
              href="https://whatzon.page.link/gRxr"
              className="uppercase"
              target="_blank"
              rel="noreferrer"
            >
              WhatzOn Online
            </a>
          </li> */}

          <li
            className={`${
              hash === "#online"
                ? "border-cornflower-blue -translate-y-0.5"
                : "border-transparent"
            } border-b font-medium smooth-animate hover:border-cornflower-blue
          hover:-translate-y-0.5 sm:w-auto w-full sm:py-0 py-2 sm:px-0 px-4`}
            onClick={() => setShow(false)}
          >
            <Link to="/#online">WhatzOn ONLINE</Link>
          </li>
        </ul>
      </nav>
      {show ? (
        <button
          onClick={() => {
            setShow(false);
          }}
          className="sm:hidden absolute top-0 right-0 bottom-0 px-4"
        >
          <MenuCloseSVG />
        </button>
      ) : (
        <button
          onClick={() => {
            setShow(true);
          }}
          className="sm:hidden absolute top-0 right-0 bottom-0 px-4"
        >
          <MenuSVG />
        </button>
      )}
    </Fragment>
  );
};

export default LayoutHeaderNav;
