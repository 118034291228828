import { FC, Fragment, ReactNode } from "react";
import LayoutFooter from "./footer";
import LayoutHeader from "./header";

type LayoutProps = {
  children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <Fragment>
      <title>{"WhatzOn"}</title>
      <LayoutHeader />
      <main className="mt-24 min-h-[400px]">{children}</main>
      <LayoutFooter />
    </Fragment>
  );
};

export default Layout;
